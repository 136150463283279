import axios from 'axios';
import qs from 'qs';

//Здесь меняем ссылку
const server_dkeysInfo = axios.create({
     baseURL: 'https://sprfwi.d-keys.info:38257/server/MAIN.php'
    //baseURL: 'https://dkeys-info.smd.local:38257/server_copy/MAIN.php'
});
const l2 = axios.create({
    baseURL: 'https://cmdc.s-m-d.ru:8626/openDoorByLink'
   // babelURL: 'http://192.168.250.33:8625/openDoorByLink'
});

export const getAccept = () => {
    let value = Array.from({ length: 10 }, () => Math.floor(Math.random() * 101)).join("")
    // Объединяем ссылку и значения в одну строку
    const dataToHash = `${window.location.search.slice(1)}|${value}`;
    return dataToHash; // Возвращаем сгенерированный токен
  };


//Запросы для открытий
export const openLink = {
    async getOpenLinkData(link) {
        let send = {
            link: link,
            action: 'linkOpen'
        }
        try {
            const res = await server_dkeysInfo.post(``, qs.stringify(send));
            return res.data
        } catch (error) {
            console.error(error)
            return { DATA: null, error: true, message: "Oops, some unforeseen errors :(" }
        }
    },

    async openDoor(link){
        const token = getAccept(link);
        console.log(token);
        let send = {
            link: link,
        }
        try {
            const res = await l2.post(``, send, 
            {
                headers: {
                //   Authorization: `Bearer ${token}`,
                //   withCredentials: true,
                  'Content-Type': 'text/plain; charset=utf-8; application/json',
                },
              }
              );
            return res.data
        }catch(error){
            console.log(error)
            return {DATA: null, error: true, message: "Oops, some unforeseen errors :("}
        }
    },

}

//Запросы для статей
export const articleCodeMP = {
    async getArticle(codeMP) {
        const send = {
            codeMP: codeMP,
            action: "getDataByCodeMP"
        }
        try {
            const response = await server_dkeysInfo.post(``, qs.stringify(send));
            return response.data
        } catch (error) {
            console.error(error)
            return { DATA: null, error: true, message: "Опаньки, какие-то не предвиденные ошибки :(" }
        }
    }
}