import React from "react"
import error404 from "../assets/error404.png";
import s from "./NotFound.module.css"

const NotFound = () => {
    return (
        <div className={s.container}>
            <img className={s.error404} src={error404} />
            <h3>Page not found.</h3>
            <h3>Check the link.</h3>            
        </div>
    )
}

export default NotFound;
